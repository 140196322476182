import { createContext } from "react";

export interface PolicyContextType {
  cookieAcceptance: boolean|undefined;
  termsAcceptance: boolean|undefined;
}
export const PolicyDispatchContext = createContext<React.Dispatch<PolicyAction>>(null as any);

export type PolicyAction = Partial<PolicyContextType>;

export const PolicyContext = createContext<PolicyContextType>(
  {
    cookieAcceptance: undefined,
    termsAcceptance: undefined,
  }
);