import './src/styles/global.css'
import '@fontsource/open-sans'
import { ContextProvider } from './src/context/ContextProvider'
import React from "react"
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// in gastby-browser.js
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location
  // list of routes for the scroll-to-top-hook
  const scrollToTopRoutes = [`/thisPageShouldNotScroll`]
  // if the new route is part of the list above, scroll to top (0, 0)
  if (scrollToTopRoutes.indexOf(pathname) === -1) {
    window.scrollTo(0, 0)
  }

  return false
}

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
)