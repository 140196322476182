import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react"
import {
  PolicyAction,
  PolicyDispatchContext,
  PolicyContext,
  PolicyContextType,
} from "./PolicyContext"

const initialPolicy = {
  cookieAcceptance: undefined,
  termsAcceptance: undefined,
}

export const ContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [policy, dispatch] = useReducer(policyReducer, initialPolicy)

  return (
    <PolicyContext.Provider value={policy}>
      <PolicyDispatchContext.Provider value={dispatch}>
        {children}
      </PolicyDispatchContext.Provider>
    </PolicyContext.Provider>
  )
}

export function usePolicy() {
  return useContext(PolicyContext)
}

export function usePolicyDispatch() {
  return useContext(PolicyDispatchContext)
}

function policyReducer(policy: PolicyContextType, action: PolicyAction) {
  if(Object.keys(action).length !== 1) throw new Error("Exactly one action must be provided");

  switch (Object.keys(action)[0] as keyof PolicyContextType) {
    case "cookieAcceptance": {
      return { ...policy, cookieAcceptance: action.cookieAcceptance }
    }
    case "termsAcceptance": {
      return { ...policy, termsAcceptance: action.termsAcceptance }
    }
    default: {
      throw Error("Unknown action: " + Object.keys(action)[0])
    }
  }
}
